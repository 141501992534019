import { defineComponent } from 'vue';
export default defineComponent({
    name: 'term-conditions-form',
    components: {},
    data() {
        return {
            isSubmitting: false,
        };
    },
    methods: {
        onSubmit() {
            // this.isSubmitting = true;
            // const { user } = this;
            // const roleId = user.role.id;
            // if (this.isProjectOwner) {
            //   await this.updateUser({ user: { roleId, hasAccountSetup: true }, userId: user.id });
            // }
            // this.saveUserState(await this.getUser(this.user.id));
            // // this.getUserPublicProfile();
            // // this.getAccountStatuses();
            // window.location.href = `/${DASHBOARD_ROUTE}`;
        }
    }
});
